.table-container {
    border: none;
    margin: 0px 10px;
}

.table-cell-left {
    border: none;
    width:30%;
}
.text-left-column{
    font-size: 12px;
    padding: 10px;
}

.table-cell-large {
    padding: 10px;
}

.table-header {
    font-size: 12px;
}
