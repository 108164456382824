.App {
  width: 100%;
  text-align: center;
  display: flex;
}
@font-face {
  font-family: 'Orbitron';
  src:url('./fonts/Orbitron-VariableFont_wght.ttf') format('truetype');
}


