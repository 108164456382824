/* In your CSS file (e.g., App.css or index.css) */
@import url('https://fonts.googleapis.com/css2?family=Francois+One&family=Biryani:wght@300&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


/* Custom scrollbar styling */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  height: 12px; /* Height for horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
  border: 3px solid transparent; /* Padding around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color when hovered */
}


/* For Firefox */
* {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 transparent; /* Thumb color and track color */
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
